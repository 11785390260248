import React, { Component } from 'react';

class ContainerMid extends Component {
    constructor(props){
        super(props);
        this.state = {
            collMid: [
                {
                    id: 1,
                    title: 'Innovación en la Administración Pública',
                    text: 'Impulsando políticas que mejoran la inclusión y la accesibilidad en los sistemas administrativos públicos.'
                },
                {
                    id: 2,
                    title: 'Capacitación Continua',
                    text: 'Comprometido con el aprendizaje constante, participando activamente en diplomados y cursos de formación política.'
                },
                {
                    id: 3,
                    title: 'Gestión y Liderazgo',
                    text: 'Dirigiendo equipos hacia la excelencia en la gestión pública, enfocándose en la inclusión y la equidad social.'
                }
            ]
        }
    }
    render() {
        return (
            <div className="chapter-list border-right-0">
                 {
                    this.state.collMid.map((data,i) => (
                        <div className="chapter-item" key={i}>
                            <h4><span>{data.subtitle} </span> {data.title}</h4>
                            <p>{data.text} </p>
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default ContainerMid;
