import React, { Component } from 'react';

class Footer extends Component {
    render() {
      // Estilos en línea para los íconos
      const iconStyle = {
        marginRight: '15px', // Espacio entre íconos
        display: 'inline'    // Asegura que los íconos se muestren en línea
      };
  
      return (          
            <section className="footer pt-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 me-auto col-sm-6 col-md-12">
                            <div className="widget footer-widget mb-5 mb-lg-0">
                                <h5 className="widget-title">Jahir García Reyes</h5>
                                <p className="mt-3">Más que una ventana a una carrera, es un punto de encuentro para todos aquellos interesados en fortalecer la inclusión y la eficacia.</p>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-sm-6 col-md-12">
          <div className="row">
            <div className="col-lg-10 col-md-10">
              <div className="footer-widget mb-5 mb-lg-0">
                <h5 className="widget-title">Conecta conmigo</h5>
                <p>Si tienes ideas, preguntas o quieres sumarte a mi proyecto de cambio positivo, no dudes en conectarte.</p>
                <div>
                  <a href="https://www.facebook.com/JahirGarciaReyes2" style={iconStyle}><i className="fab fa-facebook-f"></i></a>
                  <a href="https://www.instagram.com/jahirgarciarey/" style={iconStyle}><i className="fab fa-instagram"></i></a>
                  {/* <a href="https://www.tiktok.com/@marlene.puertas?_t=8jh3VuuEPkk&_r=1" style={iconStyle}><i className="fab fa-twitter"></i></a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
                    </div>
                </div>

                <div className="footer-btm">
                    <div className="container" >
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="copyright">
                                    <p>@ 2024-Todos los derechos reservados</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                            <div className="copyright">
                            <a href="/PrivacyPolicy">Política de Privacidad</a>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div className="fixed-btm-top">
                    <a href="#top-header" className="js-scroll-trigger scroll-to-top"><i className="fa fa-angle-up"></i></a>
                </div>
            </section>
        );
    }
}

export default Footer;
