import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class About extends Component {
    constructor(props){
        super(props);
        this.state = {
            about: [
                {
                    id: 1,
                    icon: 'bi bi-badge2',
                    title: 'Perfil Profesional',
                    text: 'Más de 10 años liderando iniciativas públicas con un enfoque en la innovación social y la equidad.'
                },
                {
                    id: 2,
                    icon: 'bi bi-rocket2',
                    title: 'Trayectoria Destacada',
                    text: 'Ocupando posiciones como Regidor Constitucional del H. Ayuntamiento de Mineral de la Reforma, y se desempeñó como presidente de comisiones en el Parlamento Juvenil, enfocándose en la justicia y el medio ambiente.'
                },
                {
                    id: 3,
                    icon: 'bi bi-rocket1',
                    title: 'Compromiso con la Excelencia',
                    text: 'Con integridad y dedicación al servicio público, promoviendo mejoras significativas en la inclusión y las políticas sociales.'
                }
            ]
        }
    }
    render() {
        return (
        <section className="about-section section-padding bg-grey" id="about">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="about-img">
                            <img src="assets/images/banner/book.jpg" alt="" className="img-fluid"/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="section-heading">
                            <span className="subheading">Acerca de </span>
                            <h3 className="heading-title">Experiencia en Administración Pública</h3>
                            <p>Profesional con amplia experiencia en políticas públicas y administración. Con una carrera que abarca roles críticos en la inclusión social y la gestión de políticas inclusivas.</p>
                        </div>

                        {
                            this.state.about.map((data,i) => (
                                <div className="about-text-block" key={i}>
                                    <i className={data.icon}></i>
                                    <h4>{data.title}</h4>
                                    <p>{data.text} </p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>  
        );
    }
}

export default About;
