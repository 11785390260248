import React, { Component } from 'react';

class FeatureLeft extends Component {
    constructor(props){
        super(props);
        this.state = {
            collLeft: [
                {
                    id: 1,
                    icon: 'fa fa-check',
                    title: 'Licenciatura en Ciencias Políticas',
                    text: 'Formación sólida en la Universidad Autónoma del Estado de Hidalgo, base de mi expertise en políticas públicas y administración.'
                },
                {
                    id: 2,
                    icon: 'fa fa-check',
                    title: 'Diplomados en Procesos Legislativos y Políticos',
                    text: 'Estudios avanzados en México y Colombia, enfocados en la formación legislativa y política'
                },
                // {
                //     id: 3,
                //     icon: 'fa fa-check',
                //     title: 'Madres en Marcha',
                //     text: 'Con un enfoque en el apoyo integral a las madres solteras, esta propuesta incluye acceso a oportu- nidades de empleo y servicios de formación profesional, además de cuidado infantil, para empoderar a estas mujeres a alcanzar su pleno potencial.'
                // }
            ]
        }
    }

    render() {
        return (
            <div className="topic-items">
            {
                    this.state.collLeft.map((data,i) => (
                        <div className="topic-item" key={i}>
                            <div className="icon-box">
                                <i className={data.icon}></i>
                            </div>
                            <div className="topic-content">
                                <h4>{data.title}</h4>
                                <p>{data.text}</p>
                            </div>
                        </div>
                    
                    ))
            }
            </div>
        );
    }

}

export default FeatureLeft;
